import Layout from '../components/layout/LayoutComponent'
import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql} from 'gatsby'
import CatalogoImage from '../components/CatalogoImage';
import evento1 from '../assets/Evento1.mp4'
import evento2 from '../assets/Evento2.mp4'
import eventoimagen1 from '../assets/EventoImagen1.jpg'

const IndexPage = () => {
  const dataQuery = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "pdfs"}}) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 300)
            }
            extension
          }
        }
      } 
    }

  `)

  const pdfs = dataQuery.allFile.edges.map((pdf) => pdf.node);

  return (
    <Layout pageTitle="Eventos" content='' isHome={true}>
      <>
      <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
                <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 mt-20 bg-white/0 flex-wrap'>
                    <h2 className='text-center text-3xl font-bold'>Eventos</h2>
                </div>  
            <div className='flex flex-col 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>


            
                <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                    <img src={eventoimagen1} alt={"Lesiones Deportivas"} />
                </div>
                <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                    <video controls>
                        <source src={evento1} type="video/mp4" />
                    </video>
                </div>
                <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                    <video controls>
                        <source src={evento2} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
      </>
    </Layout>
  )
}
export default IndexPage

export const Head = () => (
  <>
    <title>Contactanos | NecarMed</title>
  </>
)
